.body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1200px;
  width: 100%;
}

.tokenButton {
  display: inline-block;
  margin: 1em 1em 0 0;
}

.result {
  padding-top: 0;
}

.steps {
  max-width: 720px;
  margin: 0 auto;
}

.body :global(.ant-result-icon) {
  display: none;
}

.apiTokenWrapper {
  display: flex;
  justify-content: center;
}

.code {
  padding: 1em;
  text-align: center;
}

.signup-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body {
  position: relative;
  z-index: 100;

  width: 100%;
  max-width: 420px;
}

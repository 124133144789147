.login-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body {
  position: relative;
  z-index: 100;

  width: 100%;
  max-width: 420px;
}

.migration {
  padding: 0.5rem;
  background-color: #fcdbd3;
  margin-bottom: 1rem;
}

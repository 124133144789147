.plan {
  max-width: 420px !important;
}

.plans {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 2em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media only screen and (max-width: 767px) {
  .plan:not(first-child) {
    margin-top: 1em;
  }
}

.currency {
  color: var(--hero-color);
  display: flex;
  justify-content: flex-end;
}

.features {
  display: grid;
  grid-gap: 4em;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.feature {
  text-align: center;
}

.feature h3 {
  font-size: 1.3em;
  font-weight: 500;
}

.illustration {
  width: 192px;
  max-height: 72px;
  margin-bottom: 1em;
}

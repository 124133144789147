:root {
  /* TODO add postcss to resolve vars for IE11 */
  --filter-tint: brightness(1.2);
  --color-fg: #3a3a3a;
  --max-width: 1200px;
  --max-width-half: 600px;
  --space-xs: 4px;
  --space-s: 8px;
  --space-m: 16px;
  --space-l: 24px;
  --space-xl: 32px;
  --space-xxl: 48px;
  --space-xxxl: 64px;
  --side-gutter-half: var(--space-m);
  --side-gutter: var(--space-xl);
  --font-family: 'Avenir Next', 'Lato', 'Helvetica Neue', sans-serif;
  --transition-all: all 250ms ease;
  --transition-all-fast: all 125ms ease;

  --line-height-m: 1.375;
  --line-height-l: 1.42;
}

.theme-waves {
  color: #fff;
  font-family: var(--font-family);
  font-size: 16px;
  overflow-x: hidden;
}

.theme-waves h1,
.theme-waves h2,
.theme-waves h3,
.theme-waves h4,
.theme-waves h5,
.theme-waves h6 {
  color: var(--color-fg);
  font-family: var(--font-family);
  font-weight: 400;
}

.theme-waves p {
  color: var(--color-fg);
}

.theme-waves b,
.theme-waves strong {
  font-weight: 700;
}

.theme-waves .paper {
  border-radius: 8px;
  padding: var(--space-l);
}

.theme-waves .loading {
  background: white;
}

.theme-waves .loading .loading-icon,
.theme-waves .loading .title {
  color: var(--color-fg) !important;
}

/*
 * Section
 * ----------------------------
 */

.theme-waves .section {
  margin-bottom: var(--space-xxl);
  margin-top: var(--space-xxl);
  padding-left: var(--side-gutter);
  padding-right: var(--side-gutter);
}

@media (min-width: 48em) {
  .theme-waves .section {
    padding-left: var(--side-gutter);
    padding-right: var(--side-gutter);
  }
}

.theme-waves .section .title {
  font-size: 28px;
  position: relative;
}

@media (min-width: 48em) {
  .theme-waves .section .title {
    font-size: var(--heading-font-size);
  }
}

.theme-waves .main .section .title {
  color: var(--hero-color);
}

.theme-waves .main .section .subtitle {
  color: var(--hero-color);
}

.theme-waves .section .subtitle {
  color: var(--color-fg);
  font-size: 22px;
  opacity: 0.9;
}

@media (min-width: 48em) {
  .theme-waves .section .subtitle {
    font-size: 24px;
  }
}

.theme-waves .section .content {
  flex: initial;
  max-width: var(--max-width);
  padding: 0 !important;
  z-index: 1;
}

.theme-waves .section-divider {
  display: none;
}

.theme-waves .section:last-of-type {
  margin-bottom: calc(2 * var(--space-xxl));
}

/*
 * Home page
 * ----------------------------
 */

.theme-waves .home-page .hero-section {
  margin-top: var(--space-xxl);
}

@media (min-width: 48em) {
  .theme-waves .home-page .hero-section .content,
  .theme-waves .home-page .demo-section .content {
    max-width: var(--max-width-half);
  }
}

.theme-waves .main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theme-waves .main .main-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  width: 100%;
  height: 100%;
  max-width: 1440px;
  min-height: calc(max(600px, 100vh));
  padding-top: 120px;
}

@media only screen and (max-width: 767px) {
  .theme-waves .main .main-body {
    min-height: calc(max(500px, 100vh - 80px));
    padding-top: 0;
  }
}

/*
 * Home page, Hero section
 * ----------------------------
 */

@media (min-width: 48em) {
  .theme-waves .home-page .hero-section {
    padding-right: 0;
  }
}

.theme-waves .home-page .hero-section .content {
  align-items: center;
  justify-content: center;
  flex: 1 1;
}

@media (min-width: 48em) {
  .theme-waves .home-page .hero-section .content {
    align-items: flex-start;
    text-align: left;
    margin-left: auto;
    padding-right: var(--space-xl) !important;
    z-index: 1;
  }

  .theme-waves .home-page .hero-section .content .title,
  .theme-waves .home-page .hero-section .content .subtitle,
  .theme-waves .home-page .hero-section .content .desc {
    text-align: left;
  }

  .theme-waves .home-page .main-body-centered .hero-section .content {
    align-items: center;
    text-align: center;
  }

  .theme-waves .home-page .main-body-centered .hero-section .content .title,
  .theme-waves .home-page .main-body-centered .hero-section .content .subtitle,
  .theme-waves .home-page .main-body-centered .hero-section .content .desc {
    text-align: center;
  }
}

.theme-waves .home-page .hero-section .title {
  line-height: var(--line-height-m);
  margin: 0;
  margin-bottom: var(--space-m);
  font-weight: 400;
}

.theme-waves .home-page .hero-section .subtitle {
  line-height: var(--line-height-l);
  margin: 0;
  margin-bottom: var(--space-l);
}

/*
 * Home page, Demo section
 * ----------------------------
 */

.theme-waves .home-page .demo-section {
  display: none;
  background: transparent !important;
  justify-content: center;
}

@media (min-width: 48em) {
  .theme-waves .home-page .demo-section {
    display: flex;
    background: transparent !important;
    padding-left: 0;
  }
}

.theme-waves .home-page .demo-section .content {
  font-weight: 400;
  height: 100%;
  justify-content: center;
  margin-right: auto;
}

@media (min-width: 48em) {
  .theme-waves .home-page .demo-section .content {
    padding-left: var(--side-gutter-half) !important;
  }
}

.theme-waves .home-page .demo-section .desc {
  display: none;
}

@media (min-width: 48em) {
  .theme-waves .home-page .demo-section .title {
    display: none;
  }
}

/*
 * Home page, Features section
 * ----------------------------
 */

.theme-waves .home-page .features-section .title,
.theme-waves .home-page .features-section .desc {
  display: none;
}

.theme-waves .features-section .features {
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr)) !important;
}

.theme-waves .features-section .feature {
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}

.theme-waves .features-section .feature .illustration {
  max-height: 120px !important;
  width: 220px;
}

.theme-waves .features-section .feature h3 {
  font-weight: 600 !important;
}

/*
 * Pricing page
 * ----------------------------
 */

.theme-waves .pricing-page .pricing-section {
  width: 100%;
  height: auto;
  min-height: 500px;
  z-index: 1;
}

/*
 * Checkout page
 * ----------------------------
 */

.theme-waves .checkout-page {
  background: #f7f7f7;
  padding: 0;
}

.theme-waves .checkout-page :global(.box-gallery) {
  display: none;
}

.theme-waves .checkout-page .title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: var(--space-m);
}

.theme-waves .checkout-page > .content {
  display: flex;
  align-items: center;
  padding: var(--space-m);
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

@media (min-width: 48em) {
  .theme-waves .checkout-page > .content {
    justify-content: center;
    flex-direction: row;
    padding-top: var(--space-xxl);
  }
}

.theme-waves .checkout-page .content .form :global(.ant-form-item) {
  text-align: left;
}

.theme-waves .checkout-page .content .form .label {
  font-weight: 500;
}

.theme-waves .checkout-page .plan {
  margin-bottom: var(--space-l);
  margin-left: 0;
  margin-right: 0;
}

.theme-waves .plan .name {
  letter-spacing: 4px;
  color: rgba(0, 0, 0, 0.85) !important;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.theme-waves .plan .features {
  font-size: 0.85em;
}

@media (min-width: 48em) {
  .theme-waves .checkout-page .plan {
    margin-bottom: 0;
    margin-right: var(--space-l);
  }
}

.theme-waves .checkout-page .form button {
  min-width: 33%;
}

/*
 * Login page
 * ----------------------------
 */

.theme-waves .login-page,
.theme-waves .signup-page {
  background: #f7f7f7;
}

.theme-waves .login-page .body,
.theme-waves .signup-page .body {
  max-width: 352px;
}

.theme-waves .login-page .title,
.theme-waves .signup-page .title {
  font-weight: 500;
}

.theme-waves .login-page .content,
.theme-waves .signup-page .content {
  background: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: center;
}

.theme-waves .login-page .content .form,
.theme-waves .signup-page .content .form {
  margin: 0;
  padding: 0;
  width: 100%;
}

.theme-waves .login-page .content .form > button.submit {
  margin-bottom: var(--space-m);
}

.theme-waves .login-page .content .form :global(.ant-form-item),
.theme-waves .signup-page .content .form :global(.ant-form-item) {
  text-align: left;
}

/*
 * Dashboard
 * ----------------------------
 */
.theme-waves .dashboard-page,
.theme-waves .dashboard-page .section {
  background: #f7f7f7 !important;
}

.theme-waves .dashboard-page .section:first-of-type::before {
  display: none;
}

.theme-waves .dashboard-page .dashboard-section .title {
  color: var(--color-fg);
  font-weight: 600;
  font-size: 48px;
}

.theme-waves .dashboard-page .section:first-of-type::after {
  display: none;
}

.theme-waves .dashboard-page .onboarding-section {
  margin-top: 0;
}

.theme-waves .dashboard-page .section:not(.dashboard-section) .title {
  font-weight: 600;
  font-size: 22px;
}

.theme-waves .dashboard-page td button {
  padding: var(--space-s) var(--space-m);
}

/*
 * NavHeader
 * ----------------------------
 */
.theme-waves .nav-header {
  border-bottom: var(--nav-border-width) solid #e1e1e1;
  padding: var(--side-gutter-half);
  transition: var(--transition-all-fast);
}

@media (min-width: 48em) {
  .theme-waves .nav-header {
    padding-bottom: var(--space-m);
    padding-left: var(--side-gutter);
    padding-right: var(--side-gutter);
    padding-top: var(--space-m);
  }
}

.theme-waves .nav-header .link {
  color: var(--hero-color) !important;
  font-size: 18px;
  font-weight: 500;
  margin-right: 10%;
}

.theme-waves .nav-header .link:hover {
  color: var(--color-primary) !important;
  filter: var(--filter-tint);
}

.theme-waves .nav-header .links .link:nth-of-type(3) {
  order: 3;
  opacity: 0.9;
}

.theme-waves .nav-header .links .link:nth-of-type(1) {
  order: 2;
  opacity: 0.9;
}

.theme-waves .nav-header .links .link:nth-of-type(2) {
  order: 1;
  opacity: 0.9;
}

.theme-waves .nav-header .actions {
  justify-content: initial;
}

@media (min-width: 48em) {
  .theme-waves .nav-header .actions {
    justify-content: space-around;
    margin-top: 0;
  }
}

.theme-waves .nav-header .actions .link:first-of-type {
  margin-left: 0;
  margin-right: var(--space-s);
}

@media (min-width: 48em) {
  .theme-waves .nav-header .actions .link:first-of-type {
    margin-left: var(--space-xl);
    margin-right: 0;
  }
}

.theme-waves .nav-header .actions .link:last-of-type {
  margin-left: var(--space-s);
  margin-right: 0;
}

@media (min-width: 48em) {
  .theme-waves .nav-header .actions .link:last-of-type {
    margin-left: var(--space-m);
  }
}

.theme-waves .nav-header .actions .link,
.theme-waves .nav-header .actions .link > button {
  width: 100%;
}

@media (min-width: 48em) {
  .theme-waves .nav-header .actions .link {
    margin-left: var(--space-m);
    width: auto;
  }
}

.theme-waves header .content {
  max-width: var(--max-width) !important;
}

.theme-waves header .primary .link {
  margin-left: 0;
  margin-right: 0;
}

.theme-waves header.attached {
  background: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
}

@media (min-width: 48em) {
  .theme-waves header.attached .link {
    color: var(--color-fg) !important;
  }
}

.theme-waves header .logo {
  max-height: 48px;
}

.theme-waves header:not(.attached) .logo--light {
  display: var(--logo-light-top-display);
}

.theme-waves header:not(.attached) .logo:not(.logo--light) {
  display: var(--logo-top-display);
}

.theme-waves header .logo-text {
  font-weight: 600;
}

.theme-waves header.attached .logo-text:not(:hover),
.theme-waves header.attached .links .link:not(:hover),
.theme-waves header.expanded .logo-text:not(:hover),
.theme-waves header.expanded .links .link:not(:hover) {
  color: var(--color-fg) !important;
}

@media only screen and (max-width: 767px) {
  .theme-waves header .logo-text:not(:hover),
  .theme-waves header .links .link:not(:hover),
  .theme-waves header .logo-text:not(:hover),
  .theme-waves header .links .link:not(:hover) {
    color: var(--color-fg) !important;
  }
}

/*
 * Footer
 * ----------------------------
 */

.theme-waves footer {
  background-color: #f9f9fb !important;
  border-top: 1px solid #efefef;
}

.theme-waves footer .detail,
.theme-waves footer .header {
  color: var(--color-fg) !important;
  opacity: 0.9;
}

.theme-waves footer .link {
  color: var(--color-fg) !important;
  opacity: 0.9;
}

.theme-waves footer .link:hover {
  color: var(--color-primary) !important;
  filter: var(--filter-tint);
  opacity: 1;
}

/*
 * CTAs
 * ----------------------------
 */

.theme-waves a {
  transition: var(--transition-all);
}

.theme-waves a:hover {
  transition: var(--transition-all);
}

/* TODO: cta-button ghost should have dark text */
.theme-waves .cta-button,
.theme-waves .cta-button.inline,
.theme-waves :global(.ant-btn) {
  border: var(--color-primary) solid 1px !important;
  border-radius: var(--border-radius);
  font-size: 16px !important;
  font-weight: 500;
  height: auto;
  line-height: initial;
  letter-spacing: normal !important;
  text-transform: none !important;
  text-shadow: none;
  padding-bottom: var(--space-s);
  padding-top: var(--space-s);
  padding-left: var(--space-xl);
  padding-right: var(--space-xl);
  transition: var(--transition-all);
}

.theme-waves .hero-section .cta-button,
.theme-waves .cta-section .cta-button {
  font-size: 20px !important;
  line-height: 1.5em;
}

.theme-waves .cta-button:global(.primary),
.theme-waves :global(.ant-btn) {
  background: var(--color-primary) !important;
  color: white !important;
}

.theme-waves .cta-button:global(.secondary),
.theme-waves :global(.ant-btn-secondary) {
  background: transparent !important;
  color: var(--color-primary) !important;
}

.theme-waves .docs-page .nav-header .cta-button:global(.secondary),
.theme-waves .docs-page .nav-header :global(.ant-btn-secondary) {
  background: transparent !important;
  border-color: #fff !important;
  color: #fff !important;
}

.theme-waves .cta-button:hover,
.theme-waves .cta-button:focus,
.theme-waves :global(.ant-btn):hover,
.theme-waves :global(.ant-btn):focus {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.11), 0 3px 6px rgba(0, 0, 0, 0.08);
  filter: var(--filter-tint);
}

.theme-waves .cta-button:global(.secondary):hover,
.theme-waves .cta-button:global(.secondary):focus,
.theme-waves :global(.ant-btn-secondary):hover,
.theme-waves :global(.ant-btn-secondary):focus {
  background: var(--color-primary) !important;
  color: #fff !important;
}

.theme-waves.dark-bg
  .nav-header:not(.expanded):not(.attached)
  .cta-button:global(.secondary),
.theme-waves.dark-bg
  .nav-header:not(.expanded):not(.attached)
  :global(.ant-btn-secondary) {
  background: transparent !important;
  border-color: #fff !important;
  color: #fff !important;
}

.theme-waves.dark-bg
  .nav-header:not(.expanded):not(.attached)
  .cta-button:global(.secondary):hover,
.theme-waves.dark-bg
  .nav-header:not(.expanded):not(.attached)
  .cta-button:global(.secondary):focus,
.theme-waves.dark-bg
  .nav-header:not(.expanded):not(.attached)
  :global(.ant-btn-secondary):hover,
.theme-waves.dark-bg
  .nav-header:not(.expanded):not(.attached)
  :global(.ant-btn-secondary):focus {
  background: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: #fff !important;
}

.theme-waves .docs-page .nav-header .cta-button:global(.secondary):hover,
.theme-waves .docs-page .nav-header :global(.ant-btn-secondary):hover {
  background: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: #fff !important;
}

.theme-waves .cta-button:disabled,
.theme-waves .cta-button:disabled:hover,
.theme-waves .cta-button.inline:disabled,
.theme-waves .cta-button.inline:disabled:hover,
.theme-waves :global(.ant-btn):disabled,
.theme-waves :global(.ant-btn):disabled:hover {
  background-color: #a1a5a9 !important;
  border-color: #a1a5a9 !important;
  color: #fff !important;
  opacity: 0.7;
}

.theme-waves :global(.ant-btn-icon-only) {
  width: 40px !important;
  height: 40px !important;
  padding: 8px !important;
}

@media only screen and (max-width: 767px) {
  .theme-waves.dark-bg
    .nav-header:not(.expanded):not(.attached)
    .cta-button:global(.secondary),
  .theme-waves.dark-bg
    .nav-header:not(.expanded):not(.attached)
    :global(.ant-btn-secondary) {
    background: transparent !important;
    border-color: var(--color-primary) !important;
    color: var(--color-primary) !important;
  }

  .theme-waves
    .docs-page
    .nav-header:not(.expanded)
    .cta-button:global(.secondary),
  .theme-waves
    .docs-page
    .nav-header:not(.expanded)
    :global(.ant-btn-secondary) {
    background: transparent !important;
    border-color: #fff !important;
    color: #fff !important;
  }
}

/*
 * Code Block
 * ----------------------------
 */

.theme-waves .live-service-demo {
  background: var(--code-block-background);
  border-radius: 8px;
  box-shadow: none !important;
  filter: drop-shadow(0px 3px 6px var(--code-block-shadow-color));
  overflow: hidden;
}

@media (min-width: 48em) {
  .theme-waves .live-service-demo {
    margin-top: var(--space-xxxl);
  }
}

@media (min-width: 48em) {
  .theme-waves .live-service-demo .tabs:before {
    align-self: center;
    color: #ffffff90;
    content: 'Live Demo';
    font-size: 16px;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
      monospace;
    white-space: nowrap;
  }
}

.theme-waves .live-service-demo .tabs {
  background: #313b5250;
  color: #ffffff80;
  font-size: 14px;
  padding-bottom: var(--space-m);
  padding-top: var(--space-m);
  padding-left: var(--space-l);
  padding-right: var(--space-l);
  overflow-x: auto;
}

.theme-waves .live-service-demo .tabs .tab:first-of-type {
  padding-left: 0;
}

@media (min-width: 48em) {
  .theme-waves .live-service-demo .tabs .tab:first-of-type {
    margin-left: auto;
    padding-left: var(--space-xl);
  }
}

.theme-waves .live-service-demo .tabs .tab:hover {
  background: transparent;
  color: #ffffff;
}

.theme-waves .live-service-demo .tabs .selected-tab {
  background: transparent;
  color: #ffffff;
  font-weight: 600;
}

.theme-waves .live-service-demo .tab-content {
  max-height: 32.5vh;
  position: static;
  overflow-y: auto;
}

.theme-waves .live-service-demo .output {
  max-height: 30vh;
  overflow-y: auto;
}

.theme-waves .live-service-demo .output:not(.output--hit-rate-limit) {
  background: var(--code-block-output-background);
}

.theme-waves .live-service-demo .output .img-wrapper {
  height: 30vh;
}

.theme-waves .live-service-demo .output--flush .img-wrapper {
  height: auto;
}

.theme-waves .code-block {
  background: transparent !important;
  color: white !important;
  height: auto !important;
  font-size: 14px;
  padding: var(--space-m) !important;
  padding-left: var(--space-l) !important;
  padding-right: var(--space-l) !important;
}

.theme-waves .live-service-demo .info,
.theme-waves .live-service-demo .copy {
  background: white !important;
  border: none !important;
  color: var(--color-fg) !important;
  opacity: 0.6;
}

.theme-waves .live-service-demo .copy:hover {
  opacity: 1;
}

.theme-waves .live-service-demo :global(.ant-btn-secondary):not(:hover) {
  border-color: white !important;
  color: white !important;
}

.theme-waves .live-service-demo .footer__service__path {
  color: #9c9c9c;
}

.theme-waves .live-service-demo .footer__service__example-description {
  color: #cbcbcb;
}

.theme-waves .live-service-demo .service-form__input-label {
  color: #c7c7c7;
}

/*
 * BG
 * ----------------------------
 */

.theme-waves .main {
  position: relative;

  background-image: var(--background-image);
  background-size: cover;
  background-position: top center;
}

.theme-waves .signup-page::before,
.theme-waves .login-page::before,
.theme-waves .checkout-page::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background-image: var(--background-image);
  background-size: cover;
  background-position: top;
}

@media (min-width: 48em) {
  .theme-waves .main {
    top: -118px;
  }
}

.theme-waves .main::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -64px;
  width: 100vw;
  height: 260px !important;

  background-image: var(--background-image-wave);
  background-position: center;
  background-size: cover;
}

/*
 * Pricing plans
 * ----------------------------
 */

.theme-waves .pricing-section {
  margin-top: 0;
}

.theme-waves .pricing-section .title {
  font-weight: 600;
}

.theme-waves .pricing-section::before {
  height: calc(50% + 0px) !important;
}

@media (min-width: 48em) {
  .theme-waves .pricing-section::before {
    height: calc(50% + 250px) !important;
  }
}

.theme-waves .pricing-section::after {
  top: calc(50% - 225px) !important;
}

@media (min-width: 48em) {
  .theme-waves .pricing-section::after {
    bottom: 150px !important;
    top: auto !important;
  }
}

.theme-waves .pricing-section .title {
  font-size: 40px;
  line-height: var(--line-height-m);
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-s);
}

.theme-waves .pricing-section .subtitle {
  font-size: 28px;
  line-height: var(--line-height-m);
  margin-bottom: var(--space-xxxl);
}

.theme-waves .plans {
  justify-content: center;
  margin: 0;
  margin-top: var(--space-s);
}

@media (min-width: 48em) {
  .theme-waves .plans {
    flex-wrap: nowrap;
  }
}

.theme-waves .plan {
  margin-bottom: var(--space-xxl);
  max-width: 400px;
  width: 100%;
}

@media (min-width: 48em) {
  .theme-waves .plan {
    margin: var(--space-l);
    margin-top: 0;
  }
}

.theme-waves .plan h3 {
  font-size: 28px;
  letter-spacing: initial;
  line-height: var(--line-height-m);
  font-weight: 600 !important;
  text-shadow: none;
}

/* Label */
.theme-waves .pricing-section .plan .pricing .emphasis,
.theme-waves .pricing-section .plan .pricing .column {
  font-weight: 400;
  opacity: 0.6;
  text-align: center;
}

.theme-waves .plan .cta-button {
  margin-top: var(--space-m);
  width: 100%;
}

.theme-waves .plan:first-of-type .cta-button {
  content: 'test';
}

.theme-waves .plan .price {
  align-items: baseline;
  color: #9e9e9e;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.theme-waves .plan .price .dollas {
  color: var(--color-fg);
  font-size: 28px;
  font-weight: 600;
  margin-right: var(--space-s);
}

.theme-waves .enterprise-section .content {
  max-width: 600px;
  text-align: center;
}

/*
 * Docs
 * ----------------------------
 */

.theme-waves .docs-page {
  background: white;
}

.theme-waves .docs-page pre {
  border-radius: 8px;
}

.theme-waves .docs-page header {
  background: #292929 !important;
  border-bottom: none;
}

@media (min-width: 48em) {
  .theme-waves .docs-page header.attached .link {
    color: white !important;
  }
}

.theme-waves .docs-page header .cta-button:global(.secondary):not(:hover) {
  border-color: white !important;
  color: white !important;
}

.theme-waves .docs-page header .logo-text:not(:hover),
.theme-waves .docs-page header .links .link:not(:hover) {
  color: white !important;
}

.theme-waves .docs-page .logo--light {
  display: flex !important;
}

.theme-waves .docs-page .nav-header .logo:not(.logo--light) {
  display: none !important;
}

.theme-waves .docs-page p {
  font-family: var(--font-family);
}
